<template>
  <div>
    <x-header>
      <img slot="headerImage" src="../../assets/contactUs/banner.jpg" alt="" />
      <h1 slot="headerTitle">{{$store.state.navShow == true ?'安全快捷、真情服务':'Effective and safe, sincere service'}}</h1>

    </x-header>
    <div class="contactUs_main">
      <div class="contactUs_main_l">
        <div
          class="main_items"
          v-for="(itemLeft, indexLeft) in $store.state.navShow == true ? item_detailsArrLeft: item_detailsArrLeftEn"
          :key="indexLeft"
        >
          <div class="items_title">
            <div class="title_l">
              <div class="rowLine"></div>
              <p>{{ itemLeft.name }}</p>
            </div>
            <div class="title_r">
              <img :src="'http://www.margingroup.cn' + itemLeft.thumb" alt="" />
            </div>
          </div>
          <div class="item_details">
            <div class="weizhi">
              <img src="../../assets/contactUs/weizhi.png" alt="" />
              <p class="pFont">{{ itemLeft.address }}</p>
            </div>
            <div class="emailAndPhone">
              <!-- 邮箱 -->
              <div class="emails">
                <img src="../../assets/contactUs/youxiang.png" alt="" />
                <p class="pFont">{{ itemLeft.email }}</p>
              </div>

              <div class="phones">
                <img src="../../assets/contactUs/phone.png" alt="" />
                <p>{{ itemLeft.phone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="contactUs_main_r">
        <div
          class="main_items"
          v-for="(itemRight, indexRight) in $store.state.navShow == true ?  item_detailsArrRight : item_detailsArrRightEn"
          :key="indexRight"
        >
          <div class="items_title">
            <div class="title_l">
              <div class="rowLine"></div>
              <p>{{ itemRight.name }}</p>
            </div>
            <div class="title_r">
              <img :src="'http://www.margingroup.cn' + itemRight.thumb" alt="" />
            </div>
          </div>
          <div class="item_details">
            <div class="weizhi">
              <img src="../../assets/contactUs/weizhi.png" alt="" />
              <p class="pFont">{{ itemRight.address }}</p>
            </div>
            <div class="emailAndPhone">
              <div class="emails">
                <img src="../../assets/contactUs/youxiang.png" alt="" />
                <p class="pFont">{{ itemRight.email }}</p>
              </div>
              <div class="phones">
                <img src="../../assets/contactUs/phone.png" alt="" />
                <p>{{ itemRight.phone }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 头导入
import xHeader from '../../components/header.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader
  },
  data () {
    return {
      item_detailsArrLeft: [
        {
          itemDetail_name: '河北',
          itemDetail_img: require('../../assets/contactUs/hebei.png'),
          itemDetail_weizhi: '河北省石家庄市裕华区体育南大街399号4层',
          itemDetail_emails: 'YSY@MARGINGROUP.CN',
          itemDetail_phones: '0311-8695-8033'
        },
        {
          itemDetail_name: '圣彼得堡',
          itemDetail_img: require('../../assets/contactUs/shengbidebao.png'),
          itemDetail_weizhi: 'St. Petersburg, Karl Faberge Square, 8B',
          itemDetail_emails: 'OFFICE@mLOGISTIC.SU',
          itemDetail_phones: '+7(812)448-16-36'
        }
      ],

      item_detailsArrRight: [
        {
          itemDetail_name: '上海',
          itemDetail_img: require('../../assets/contactUs/shanghai.png'),
          itemDetail_weizhi: '上海市浦东大道720号国际金融航运大厦2楼',
          itemDetail_emails: 'MGGROUP_EVA@163.com',
          itemDetail_phones: '021-205-15135'
        },
        {
          itemDetail_name: '海参崴',
          itemDetail_img: require('../../assets/contactUs/haishenwei.png'),
          itemDetail_weizhi: 'Vladivostok, Berezova Street, 25, office 418',
          itemDetail_emails: 'PS@mLOGISTIC.SU.COM',
          itemDetail_phones: '+7(423)200-62-06'
        }
      ],
      item_detailsArrLeftEn: [
        {
          itemDetail_name: '河北1',
          itemDetail_img: require('../../assets/contactUs/hebei.png'),
          itemDetail_weizhi: '河北省石家庄市裕华区体育南大街399号4层',
          itemDetail_emails: 'YSY@MARGINGROUP.CN',
          itemDetail_phones: '0311-8695-8033'
        },
        {
          itemDetail_name: '圣彼得堡',
          itemDetail_img: require('../../assets/contactUs/shengbidebao.png'),
          itemDetail_weizhi: 'St. Petersburg, Karl Faberge Square, 8B',
          itemDetail_emails: 'OFFICE@mLOGISTIC.SU',
          itemDetail_phones: '+7(812)448-16-36'
        }
      ],

      item_detailsArrRightEn: [
        {
          itemDetail_name: '上海',
          itemDetail_img: require('../../assets/contactUs/shanghai.png'),
          itemDetail_weizhi: '上海市浦东大道720号国际金融航运大厦2楼',
          itemDetail_emails: 'MGGROUP_EVA@163.com',
          itemDetail_phones: '021-205-15135'
        },
        {
          itemDetail_name: '海参崴',
          itemDetail_img: require('../../assets/contactUs/haishenwei.png'),
          itemDetail_weizhi: 'Vladivostok, Berezova Street, 25, office 418',
          itemDetail_emails: 'PS@mLOGISTIC.SU.COM',
          itemDetail_phones: '+7(423)200-62-06'
        }
      ]
    }
  },
  mounted () {
    //  友情链接与合作公司地址
    this.$axios.get('/api/Index/linksAndAddress').then((res) => {
      if (res.data.code === 1) {
        this.item_detailsArrRight = res.data.data.addressCard.filter(
          (item, index) => {
            console.log('item', item)
            return (index + 1) % 2 === 0
          }
        )

        this.item_detailsArrLeft = res.data.data.addressCard.filter(
          (item, index) => {
            return item && (index + 1) % 2 !== 0
          }
        )
      } else {
        this.$message({
          showClose: true,
          message: '地图接口' + res.data.msg,
          type: 'error'
        })
      }
    })
    this.$axios.get('/apien/Index/linksAndAddress').then((res) => {
      if (res.data.code === 1) {
        this.item_detailsArrRightEn = res.data.data.addressCard.filter(
          (item, index) => {
            console.log('item', item)
            return (index + 1) % 2 === 0
          }
        )

        this.item_detailsArrLeftEn = res.data.data.addressCard.filter(
          (item, index) => {
            return item && (index + 1) % 2 !== 0
          }
        )
      } else {
        this.$message({
          showClose: true,
          message: '地图接口' + res.data.msg,
          type: 'error'
        })
      }
    })
  }
}
</script>
<style lang="less" scoped>
.contactUs_main {
  width: var(--width1200px);
  height: 530px;
  // background: orange;
  margin: 160px auto 85px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  .contactUs_main_l,
  .contactUs_main_r {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    // background: pink;
  }
  .contactUs_main_r {
    // background: red;
    display: flex;
    justify-content: space-between;
  }
  .main_items {
    width: 590px;
    height: 225px;
    // background: pink;
    transition: all 0.35s;
    .items_title {
      width: 100%;
      height: 80px;
      background: #5289d1
        linear-gradient(90deg, rgba(3, 0, 0, 0.15), rgba(255, 255, 255, 0.15));
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title_l {
        display: flex;
        align-items: center;
        & .rowLine {
          width: 15px;
          height: 4px;
          background: #ffffff;
          margin: 0 15px;
        }
        & p {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #ffffff;
        }
      }
      .title_r {
        position: relative;
        // background: orange;
        width: 205px;
        height: 100%;
        & > img {
          position: absolute;
          bottom: 0;
          right: 0px;
        }
      }
    }
    .item_details {
      width: 100%;
      height: calc(100% - 80px);
      background: #fff;
      padding: 0 20px 0 18px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-shadow: 0px 0px 14px 0px rgba(4, 0, 0, 0.1);
      & p {
        margin-left: 15px;
      }
      & .pFont {
        font-size: 14px;
        font-family: Microsoft YaHei;
      }
      .weizhi {
        display: flex;
        p {
          color: #666;
        }
      }
      .emailAndPhone {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
        .emails {
          display: flex;
          align-items: center;
          p {
            color: #2d6aab;
            font-weight: 400;
          }
        }
        .phones {
          display: flex;
          align-items: center;
          background: linear-gradient(180deg, #3676c9 0%, #42be72 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          p {
            font-size: 30px;
            font-family: AkzidenzGroteskBQ;
            font-weight: bold;
          }
        }
      }
    }
    &:nth-child(1) {
      margin-right: 20px;
    }
    &:hover {
      transform: translateY(-15px);
    }
  }
}
</style>
